body {
  font-family: Arial, Helvetica, sans-serif;
  /* default is 1rem or 16px */
  font-size: 12px;
  font-weight: 400;
  /* line-height: 1.5; */
}
.btn {
  font-size: 12px;
}

.react-datepicker__input-container input {
  width: 100px;
}

.navbar-light {
  background: rgb(135, 206, 250);
  border-bottom: 1px solid black !important;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  font-weight: bold;
}

.navbar-nav .btn {
  font-size: 1.2em !important;
  color: black;
}

.table .thead-dark th {
  background-color: rgb(135, 206, 250);
  border-color: black;
  color: black;
}

.thead-blue {
  background: rgb(135, 206, 250);
}

table {
  font-family: Arial, Helvetica, sans-serif;
}

.table.thead-dark > thead > th {
  background: rgb(135, 206, 250);
}

table.table-bordered {
  border: 1px solid black;
  margin-top: 20px;
}

table.table-bordered > thead > tr > th {
  border: 1px solid black;
  margin-top: 20px;
}

table.table-bordered > tbody > tr > td {
  border: 1px solid black;
  margin-top: 20px;
  font-family: Arial, 'MS Sans Serif';
}

table.table-bordered > tfoot > tr > td {
  border: 1px solid black;
  margin-top: 20px;
  font-family: Arial, 'MS Sans Serif';
  font-weight: bold;
}

.form-control-xs {
  height: 25px !important;
  padding: 0.125rem 0.25rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.noborder:focus {
  box-shadow: none !important;
}

/* Increase all font sizes on mobile */
@media (max-width: 767px) {
  body {
    /* default is 1rem or 16px */
    font-size: 25px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
  padding-right: 5px;
}

.btn:focus {
  box-shadow: none !important;
}

.login-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #5e91f8;
  background: url('../img/background.jpg');
}

.login-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #5e91f8;
  background: url('../img/bg.jpg');
  background-size: cover;
}

.login-form {
  padding-top: 2rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 1rem !important;
}

.display-hidden {
  display: none;
}
